.events-based-audits main {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 0;
    /* min-height: 100vh; */
}

.events-based-audits .footer-padding {
    height: 100vh;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.filters > .filter-form {
    display: inline-flex;
    column-gap: 10px;
}

.filters > div:only-child {
    margin-left: auto;
}

.gkl-logo {
    width: 100px;
}

.table-title {
    font-family: 'Chakra-Petch-Bold';
    font-size: 1.5em;
}

.overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.9;
    z-index: 2;
    cursor: pointer;
}

.filter-options-all-container {
    display: none;
    position: absolute;
    top: 10px;
    left: 25%;
    width: 50%;
    padding: 10px;
    background: black;
    border-radius: 10px;
    border: 2px rgb(25, 240, 45) solid;
    z-index: 3;
}

.show {
    display: block;
}

/* .filter-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

.export-container {
    display: flex;
    justify-content: end;
    align-items: center;
}

.filter-button {
    background: transparent;
    border: none;
    padding-right: 10px;
}

.filter-button > img {
    width: 18px;
}

.importer {
    all: unset;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 10px;
}

.importer > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.importer > div > img {
    width: 13px;
    transform: scaleY(-1);
}

.exporter {
    all: unset;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 10px;
}

.exporter > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.exporter > div > img {
    width: 13px;
}

.filter-search-container {
    display: flex;
    justify-content: end;
    align-items: center;
}

.search-input {
    font-family: 'Chakra-Petch-Regular';
    border: none;
    height: 35px;
    width: 250px;
    color: white;
    --c: rgb(25, 240, 45);
    padding: 18px;
    outline: 0;
    box-sizing: border-box;
    background: linear-gradient( 45deg,#0000 9.9px, var(--c) 0 calc(8.9px +  3px), #0000 0 calc(100% - 8.9px -  3px), var(--c) 0 calc(100% - 9.9px), #0000 0),
                linear-gradient(-45deg,#0000 9.9px, var(--c) 0 calc(8.9px +  3px), #0000 0 calc(100% - 7.9px -  3px), var(--c) 0 calc(100% - 8.9px), #0000 0),
                linear-gradient(90deg ,var(--c) 4px,#0000 0) -2px 50%/100% calc(100% - 28px) repeat-x,
                linear-gradient(180deg,var(--c) 4px,#0000 0) 50% -2px/calc(100% - 28px) 100% repeat-y;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
}

.filter-options-container {
    display: block;
    /* grid-template-columns: 45% 45%;
    column-gap: 5%;
    justify-content: center; */
}

.filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.filter-container > label {
    font-family: 'Chakra-Petch-Semi-Bold';
    width: 50%;
}

.filter-container > label.file-label {
    width: 25%;
}

.filter-container > input {
    font-family: 'Chakra-Petch-Regular';
    border: none;
    height: 35px;
    color: white;
    --c: rgb(25, 240, 45);
    padding: 18px;
    outline: 0;
    box-sizing: border-box;
    background: linear-gradient( 45deg,#0000 9.90px, var(--c) 0 calc(9.90px +  3px), #0000 0 calc(100% - 9.90px -  3px), var(--c) 0 calc(100% - 9.90px), #0000 0),
        linear-gradient(-45deg,#0000 9.90px, var(--c) 0 calc(9.90px +  3px), #0000 0 calc(100% - 9.90px -  3px), var(--c) 0 calc(100% - 9.90px), #0000 0),
        linear-gradient(90deg ,var(--c) 6px,#0000 0) -3px 50%/100% calc(100% - 28px) repeat-x,
        linear-gradient(180deg,var(--c) 6px,#0000 0) 50% -3px/calc(100% - 28px) 100% repeat-y;
    margin: 5px;
    color-scheme: dark;
}

.filter-container > input.file-input {
    padding: 5px;
    padding-left: 18px;
}

.filter-container > img {
    width: 15px;
    margin: 5px;
}

.filter-container > select {
    width: 100%;
    font-family: 'Chakra-Petch-Regular';
    border: 2px rgb(25, 240, 45) solid;
    border-radius: 10px;
    padding: 10px;
    height: 100px;
    color: white;
    background: transparent;
    margin: 5px;
}

.filter-container > select > option {
    font-family: 'Chakra-Petch-Regular';
    color: white;
    background: black;
}

.filter-container > select > option:checked {
    color: rgb(25, 240, 45);
    background: transparent;
}

.filter-submit {
    display: flex;
    justify-content: center;
}

.filter-submit > button {
    background: transparent;
    border: none;
    color: white;
    font-family: 'Chakra-Petch-Bold';
    border-bottom: 2px rgb(25, 240, 45) solid;
}

.filter-submit > input {
    background: transparent;
    border: none;
    color: white;
    font-family: 'Chakra-Petch-Bold';
    border-bottom: 2px rgb(25, 240, 45) solid;
}

table {
    width: 100%;
    border-bottom: 2px solid;
    font-family: 'Chakra-Petch-Regular';
    margin-bottom: 10px;
    /* margin-top: 30px; */
    /* display: block; */
    /* overflow-x: auto; */
    white-space: nowrap;
}

td {
    text-align: center;
    border-bottom: 1px rgb(99, 99, 99) solid;
    padding: 5px;
    white-space: nowrap;
    /* font-size: 0.65em; */
}

td > div {
    max-height: 60px;
    max-width: 350px;
    overflow: auto;
}

.absolute-best {
    color: rgb(251 72 196);
    font-family: 'Chakra-Petch-Bold';
}

.filtered-best {
    color: rgb(25, 240, 45);
    font-family: 'Chakra-Petch-Bold';
}

th {
    text-align: center;
    /* border-bottom: 2px solid; */
    padding: 5px;
    /* font-size: 0.7em; */
    font-family: 'Chakra-Petch-Regular';
    text-wrap: wrap;
    font-weight: 100;
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
    min-width: 60px;
    max-width: 60px;
}

/* th > div {
    display: grid;
    grid-template-columns: 13% 74% 13%;
    justify-items: center;
    align-items: center;
} */

.table-heading-text {
    line-height: 12px;
    max-width: min-content;
}

.table-heading-container {
    display: flex;
    justify-content: center;
}

.name-heading {
    text-align: left;
    padding-left: 5px;
}

.name-heading > div {
    display: block;
}

.name-data {
    text-align: left;
    padding-left:5px;
}

.popup {
    display: none;
    /* position: absolute; */
    position: fixed;
    background: rgb(99, 99, 99);
    color: white;
    padding: 10px;
    border-radius: 10px;
    z-index: 1;
    font-family: 'Chakra-Petch-Regular';
    width: 25%;
    text-wrap: wrap;
}

.show {
    display: block;
}

.sort-container {
    /* margin-left: 2px; */
    margin-left: auto;
    position: absolute;
    margin-right: 0;
    right: 0;
    top: 0;
    height: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1px;
}

.metric-column {
    min-width: 100px;
    max-width: 100px;
}

thead {
    top: 0;
    position: sticky;
    background-color: black;
    border-bottom: 2px solid;
}

tbody {
    left: 0;
}

.sorter {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    padding: 2px;
    /* opacity: 0.6; */
    color: white;
    position: relative;
    line-height: 5px;
    cursor: pointer;
}

.active {
    color:rgb(25, 240, 45);
}

.sorter > img {
    width: 10px;
}

.category > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
}

.category > div > div {
    border: 1px solid;
    border-radius: 10px;
    padding: 3px;
    width: 100%;
}

.page-selection-container {
    /* display: flex; */
    display: inline-grid;
    align-items: center;
    /* justify-content: space-between; */
    /* margin-top: 30px; */
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    background:rgb(99, 99, 99);
    border-radius: 5px;
    font-family: 'Chakra-Petch-Regular';
    font-size: 0.8em;
    grid-template-columns: 30% 30% 30%;
    column-gap: 5%;
}

.results-number-selection > label {
    margin-right: 10px;
}

.results-number-selection > select {
    padding: 3px;
    border-radius: 5px;
    background: rgb(139, 139, 139);
    color: white;
    opacity: 0.8;
}

.page-selection-button {
    border: none;
    background: transparent;
    color: white;
    border-bottom: 1px solid transparent;
}

.page-selection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.next-ellipsis {
    border: none;
    background: transparent;
    color: white;
    pointer-events: none;
}

.results-number-display {
    color: white;
    opacity: 0.8;
    display: flex;
    justify-content: right;
    align-items: center;
}

.current-page {
    color:rgb(25, 240, 45);
    border-bottom: 1px solid;
}

.next > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.next > div > img {
    width: 10px;
    margin: 5px;
}